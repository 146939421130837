@import 'https://fonts.googleapis.com/earlyaccess/droidarabicnaskh.css';
* {
    box-sizing: border-box;
    outline: 0;
    padding: 0;
    margin: 0;
}
body {
    direction: rtl;
    font-family: noto kufi arabic, Rubik, sans-serif;
    background: #fff;
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
img {
    object-fit: cover !important;
    vertical-align: middle;
    width: 100%;
}
.chewa {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}
.rubar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.xat {
    background: #136aa1;
}
.layrast {
    flex: 33.33%;
    text-align: right;
    padding: 12px 5px 0 0;
}
.layrast ul > li {
    display: inline-block;
    margin-left: 30px;
}
.layrast a {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: fontawesome;
}
.laychap {
    flex: 33.33%;
    text-align: left;
    padding: 9px 0 0 5px;
}
.laychap a {
    color: #fff;
    font-size: 12px;
    padding: 0 8px;
}
.laychap .active {
    font-weight: 700;
}
.laychap a:nth-child(4) {
    border-left: 0;
    padding: 0;
}
.slideshow {
    overflow: hidden;
}
.b1 {
    width: 65%;
    float: right;
    height: 350px;
}
.b2 {
    width: 35%;
    float: right;
    height: 350px;
    box-shadow: 0 0 5px #ccc;
    background: #fff;
    border: 1px #ddd solid;
}
.qabara1 {
    width: 74.5%;
    float: right;
    height: 350px;
    position: relative;
    overflow: hidden;
}
.slideshow img {
    height: 350px;
}
.slide {
    display: none;
    height: 350px;
}
.showw {
    top: 0;
    color: #333;
    font-size: 22px;
    padding: 15px;
}
#slide:before {
    background-image: linear-gradient(transparent, #111111), linear-gradient(transparent, #111111);
    content: '';
    height: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    opacity: 0.8;
}
.newan {
}
.rastt {
    display: none;
}
.chapp {
    display: none;
}
#qabara1:before {
    background-image: linear-gradient(transparent, #111111), linear-gradient(transparent, #111111);
    content: '';
    height: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    opacity: 0.8;
}
.zhmarakan {
    width: 25.5%;
    float: right;
    height: 350px;
    padding-right: 12px;
    position: relative;
    overflow: hidden;
}
.w3-white,
.w3-hover-white:hover {
    color: #333;
    background: #fafafa;
    transition: 0.5s;
}
.raqam {
    float: right;
    position: absolute;
    right: 0;
    background: #c5c5c5;
    height: 139px;
    padding: 45px 5px 12px;
    font-size: 30px;
    color: #fff;
}
.w3-white .raqam {
    background: #136aa1;
}
.zhmarakan h4 {
    color: #333;
    padding: 20px 15px 20px 5px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s;
    height: 110px;
    background: #fff;
    border: 1px #ddd solid;
}
.demo {
    height: 120px;
}
.zhmarakanm {
    bottom: 10px;
    left: 35px;
    position: absolute;
}
.w3-transparent {
    background: #005689;
    color: #000;
    font-size: 18px;
}
.w3-white,
.w3-hover-white:hover {
    color: #333;
    background: #fff;
    transition: 0.5s;
    border: 1px #333 solid;
}
.w3-badge2 {
    height: 20px;
    width: 20px;
    padding: 0;
    display: inline-block;
    border-radius: 30px;
    text-align: center;
    line-height: 40px;
    margin: 4px 8px 4px 2px;
}
.demo img {
    width: 90px;
    height: 110px;
    float: right;
}
.zhmarakan li:nth-child(4) {
    border-bottom: unset;
}
.slide {
    position: releative;
}
.wenakanm {
    height: 420px;
}
.menu {
    padding-right: 20px;
}
.babat {
    overflow: unset;
}
.bashakan {
    overflow: unset;
}
#navbar {
    background: #fff;
    height: 42px;
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    margin-bottom: 0;
    z-index: 2;
    transition: height 1s ease;
    box-shadow: rgb(0 0 0/10%) 0 1px 3px 0;
}
.barzunzm {
    padding: 20px 0 25px;
}
#logo1 {
    padding: 1px 0 0;
    width: 215px;
    transition: 0.4s;
}
#menu {
    padding: 5px 0 0;
    transition: 0.4s;
}
#garan {
    padding: 5px 0 0;
    transition: 0.4s;
}
.sarawa_rast {
    flex: 17%;
    text-align: right;
    max-width: 17%;
    padding: 0;
    transition: 0.4s;
}
.sarawa_nawarast {
    flex: 90%;
    text-align: right;
    max-width: 90%;
}
.sarma {
    display: inline-block;
    margin: 18px 12px 0 0;
    color: #333;
    font-size: 14px;
}
.garma {
    background: #f0f1f4;
    color: #333;
    padding: 0 13px;
}
.katzhmer {
    color: #555;
    margin-right: 12px;
}
.sarawa_chap {
    flex: 10%;
    text-align: left;
    max-width: 10%;
}
.sarawa_nawarast a {
    font-size: 14px;
    color: #000;
    text-align: center;
    padding: 0 11px;
    text-decoration: none;
}
.dropdown {
    position: relative;
    display: inline-block;
    padding: 0;
    padding-bottom: 20px;
}
.dropdown-container {
    display: none;
    background-color: #12354c;
    padding-left: 8px;
    margin-left: 12px;
}
.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown-content a {
    display: block;
    text-align: right;
    padding: 5px 0;
    width: 150px;
    border-bottom: 1px #ddd dashed;
    color: #333;
}
.dropdown-content {
    display: none;
    position: fixed;
    background-color: #fff;
    min-width: 165px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0/20%);
    padding: 0 10px 0 0;
    z-index: 1;
    width: 150px;
    margin-top: 6px;
}
.sticky .dropdown-content {
    margin-top: -15px !important;
}
.saraki {
    height: 520px;
    overflow: hidden;
}
.saraki img {
    max-height: 700px;
}
.show {
    color: #fff;
    font-size: 20px;
    padding: 7px 10px 7px 25px;
    background: #fff8f82b;
    margin: 0 0 14px 20px;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.yak {
    position: relative;
    padding: 20px 0;
    background: #f0f1f4;
}
.dwam {
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    display: flex;
}
.muhim {
    padding-top: 20px;
}
.grng {
    position: relative;
}
.grng1 {
    width: 33.3332%;
    float: right;
    padding: 12px 0 0 6px;
    background: #fff;
    height: 112px;
}
.grng2 {
    width: 33.3332%;
    float: right;
    padding: 12px 6px 0;
    background: #fff;
    height: 112px;
}
.grng3 {
    width: 33.3332%;
    float: right;
    padding: 12px 6px 0 0;
    background: #fff;
    height: 112px;
}
.dwam a {
    color: #fff;
    font-size: 14px;
}
.muhim a {
    font-size: 14px;
    color: #111;
    font-weight: 500;
}
.muhim img {
    height: 90px;
    width: 90px;
}
.wena {
    float: right;
}
.rsta {
}
.rsta h4 {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f7f7f7;
    border: 1px #eee solid;
    padding: 0 6px;
    line-height: 2;
    font-size: 15px;
}
.daq {
    background: #136aa1;
    width: max-content;
    padding: 10px;
}
.title {
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.drezha {
    width: 25%;
    float: right;
}
.drezha h3 {
    padding: 30px;
    border: 1px #fff solid;
    border-radius: 10px;
    margin: 0 10px 0 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    height: 400px;
    line-height: 2;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.drezha .i1 {
    font-size: 28px;
    padding-bottom: 0;
    position: absolute;
    margin-right: 6px;
    color: #fff;
    padding: 2px;
    line-height: 10px;
}
.drezha .i2 {
    font-size: 28px;
    margin-right: -4px;
    color: #fff;
    padding: 5px;
    float: left;
    direction: ltr;
    margin-top: -24px;
    margin-left: 0;
}
.seyam {
    padding-top: 10px;
}
.hel {
    padding-bottom: 10px;
}
.hawal {
    display: inline-block;
}
.hawal .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #c31719;
}
.xabar {
    margin: 0 5px 0 0;
}
.xabar a {
    color: #c31719;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.tak {
    max-width: 75%;
    float: right;
    position: relative;
}
.ko1 {
    max-width: 25%;
    float: right;
    position: relative;
}
.hawal1 {
    width: 33.3332%;
    float: right;
    padding: 0 0 12px 12px;
}
.tak img {
    width: 100%;
    height: 170px;
}
.der1 {
    position: relative;
    width: 100%;
}
.der1 h4 {
    color: #333;
    background: #f7f7f7;
    padding: 0 10px 4px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    border-bottom: 2px solid #ccc;
    line-height: 1.8;
}
.chwar {
    padding-top: 20px;
    position: relative;
    overflow: hidden;
}
.raport .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #ffab00;
}
.raport {
    display: inline-block;
}
.report1 {
    width: 50%;
    float: right;
}
.iraqi {
    width: 100%;
    float: right;
    padding-bottom: 3px;
}
.iraqi img {
    height: 320px;
}
.report1 .der1 h4 {
    height: 63px;
    font-size: 18px;
}
.report2 {
    width: 50%;
    float: right;
}
.nuqta {
    width: 50%;
    float: right;
    padding: 0 12px 10px 0;
}
.nuqta img {
    height: 160px;
}
.nuqta .der1 h4 {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sarder5 {
    background: #fff;
    margin: 12px 0;
    min-height: 90px;
}
.der2 h4 {
    color: #333;
    background: #f7f7f7;
    padding: 0 10px 4px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #eee;
    line-height: 2;
}
.der3 h4 {
    color: #333;
    background: #f7f7f7;
    padding: 0 10px 4px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    border: 1px solid #eee;
    line-height: 2;
}
.der4 h4 {
    color: #333;
    background: #f7f7f7;
    padding: 0 10px 4px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #eee;
    padding: 20px;
}
.der6 {
    padding-bottom: 12px;
}
.der6 h4 {
    color: #333;
    background: #f9f9f9;
    padding: 0 10px 4px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    border: 1px solid #eee;
    padding: 5px;
    line-height: 2;
}
.der7 {
    width: 100%;
    bottom: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.der7 h4 {
    color: #333;
    padding: 0 10px 4px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 2;
}
.der8 h4 {
    color: #333;
    background: #f9f9f9;
    padding: 0 10px 4px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #eee;
    padding: 5px;
    line-height: 2;
}
.niw {
    width: 50%;
    float: right;
}
.tera {
    margin-left: -24px;
}
.bambo .niwa {
    width: 33.33%;
    float: right;
    padding: 0;
}
.bambo .niwa1 img {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 90px;
    height: 90px;
    filter: grayscale(100%);
    transition: 1s;
}
.bambo .niwa2 h3 {
    margin: 0 0 0 12px;
    height: 90px;
    padding: 15px 20px 0;
    font-size: 13px;
    background: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.wtarnus {
    padding-top: 12px;
}
.wtarnus a {
    color: #555 !important;
    background: #f1f3f5;
    font-size: 14px !important;
    padding: 1px 8px !important;
    font-weight: 400 !important;
}
.bambo .wtwt {
    margin-left: -12px;
}
.bambo .xawanaka {
    margin-top: 0;
}
.katwtar {
    padding: 20px;
}
.niwa {
    width: 50%;
    float: right;
    padding: 0 0 30px;
}
.niwa1 {
    width: 22%;
    float: right;
}
.niwa1 img {
    width: 130px;
    height: 110px;
    filter: grayscale(100%);
    transition: 1s;
}
.niwa1 img:hover {
    filter: unset;
    transition: 1s;
}
.niwa2 {
    float: right;
    width: 78%;
}
.niwa2 .i1 {
    font-size: 21px;
    padding-bottom: 0;
    position: absolute;
    background: #fff;
    margin-right: 6px;
    color: #aaa;
    padding: 2px;
    line-height: 10px;
}
.niwa2 .i2 {
    font-size: 21px;
    background: #fff;
    margin-right: -4px;
    color: #ccc;
    padding: 5px;
    float: left;
    direction: ltr;
    margin-top: -22px;
    margin-left: 20px;
}
.niwa2 h3 {
    padding: 20px 20px 10px;
    border: 1px #ccc solid;
    margin: 0 10px 0 25px;
    height: 110px;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 2;
}
.der5 h4 {
    color: #fff;
    background: #546e7a;
    padding: 35px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
    border-bottom: 1px solid #fff;
}
.niw img {
    height: 350px;
}
.niw h4 {
    height: 351px;
    font-size: 24px;
    line-height: 2;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.chaw {
    display: inline-block;
}
.chaw .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #546e7a;
}
.wtar .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 1.2em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #136aa1;
    margin-right: 15px;
}
.wtar .helchap:after {
    margin-right: -100%;
    content: '';
    display: inline-block;
    height: 1.2em;
    vertical-align: bottom;
    width: 93%;
    border-top: 3px solid #136aa1;
    margin-left: 15px;
}
.niwa2 .u3 {
    margin-left: 0;
}
.niwa2 .u5 {
    margin-left: 0;
}
.niwa2 .u7 {
    margin-left: 0;
}
.xawanaka {
    position: absolute;
    margin-right: 15px;
    margin-top: -15px;
}
.xawanaka a {
    font-size: 13px;
    background: #fff;
    color: #666;
    margin: 5px;
}
.aburi {
    display: inline-block;
}
.aburi .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #0089ff;
}
.life .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #d64417;
}
.life1 {
    width: 22%;
    float: right;
}
.life2 {
    width: 56%;
    float: right;
    padding: 0 12px;
    position: relative;
}
.life2 img {
    height: 375px;
}
.lifeder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    border: 1px solid #eee;
}
.life3 {
    width: 22%;
    float: right;
}
.life1 img {
    height: 130px;
}
.life3 img {
    height: 130px;
}
.hunar {
    margin-left: -12px;
}
.hunar1 {
    width: 25%;
    float: right;
    padding-left: 12px;
}
.hunar1 img {
    height: 380px;
}
.multi {
    margin-left: -12px;
}
.multi1 {
    width: 50%;
    padding-left: 12px;
    float: right;
    position: relative;
}
.multi1:after {
    color: #fff;
    /* content: url(/images/play.png); */
    font-family: FontAwesome;
    font-size: 24px;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 24px;
    position: absolute;
    top: 38%;
    z-index: 1;
    transform: translateY(-50%);
}
.multi img {
    height: 300px;
}
.charag {
    width: 51%;
    float: right;
    padding-left: 12px;
}
.rub3 {
    width: 49%;
    float: right;
}
.zher {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 2px #e5e5e5 solid;
    padding-bottom: 40px;
}
.zor {
    width: 72%;
}
.zor li {
    list-style: none;
    margin: 5px 0;
}
.nett a {
    color: #666;
    font-size: 13px;
    line-height: 2;
}
.kam {
    width: 28%;
    text-align: center;
}
.kam a {
    font-size: 17px;
    line-height: 50px;
    color: #333;
}
.kam li {
    list-style: none;
}
.kam ul > li {
    display: inline-block;
    margin-left: 5px;
    background: #f0f1f4;
    border-radius: 50%;
    width: 50px;
    padding: 0;
    text-align: center;
}
.xwar {
    float: right;
}
.sarbash {
    color: #333;
    font-weight: 700;
    font-size: 15px;
    list-style: none;
    margin-bottom: 15px;
}
.spii {
    margin: 0 0 25px;
}
.sarata {
    flex: 70%;
    max-width: 70%;
}
.block {
    flex: 30%;
    max-width: 30%;
}
.duri {
    background-color: #fff;
    border-radius: 5px;
    padding: 6px 15px 15px;
    margin: 20px 0 0 12px;
}
.lewar img {
    height: 450px;
    border-radius: 5px;
}
.jegr {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    background-color: #fff;
    border-radius: 5px;
    padding: 12px;
    margin: 20px 0 0;
}
.jegr img {
    width: 80px;
    height: 90px;
    border-radius: 15px;
    border-top-left-radius: 5px;
    float: right;
    margin-left: 6px;
    filter: grayscale(100%);
}
.jegr .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 14.5em;
    border-top: 3px solid #136aa1;
}
.nrx {
    position: absolute;
    right: 0;
    background: #ffab00;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 2px;
    border-bottom-left-radius: 15px;
    color: #fff;
    font-weight: 700;
}
.draw {
    position: absolute;
    right: 0;
    top: 5.5em;
    background: #fff;
    width: 45px;
    height: 40px;
    text-align: center;
    border-radius: 1px;
    border-top-left-radius: 15px;
    color: #136aa1;
    font-weight: 700;
    font-size: 24px;
}
.sarder4 {
    position: relative;
    height: 112px;
}
.sarder4 h4 {
    color: #111;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8;
    margin-top: 0;
}
.block .xawanaka {
    margin-right: 90px;
    margin-top: -17px;
}
.block .sarder4 h4 {
    border: 1px #eee solid;
    width: 74%;
    float: left;
    border-radius: 5px;
    padding: 15px 10px 10px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.penasa {
    border-bottom: 2px solid #f1f3f5;
    padding-bottom: 6px;
}
.penasa a {
    font-size: 12px;
    color: #444;
}
.penasa span {
    margin-left: 25px;
}
.intro {
    margin: 18px 5px 18px 0;
}
.intro h2 {
    color: #0e5d90;
    line-height: 1.8;
}
.intro h4 {
    color: #444;
    font-size: 14px;
    font-weight: 500;
}
.full {
    padding: 10px;
}
.full p {
    margin: 0 0 20px;
    font-family: droid arabic naskh, Helvetica, Arial, sans-serif !important;
}
p {
    font-family: droid arabic naskh, Helvetica, Arial, sans-serif !important;
    line-height: 2;
    font-size: 16px;
}
.video {
    text-align: center;
    justify-content: center;
    display: block;
    align-items: center;
    max-width: 800px;
}
iframe {
    max-width: 800px !important;
}
.dwain {
}
.dwain .der9 {
    color: #333;
    background: #fff;
    padding: 0 10px 4px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    border-bottom: 2px solid #136aa1;
    line-height: 1.7;
}
.hawal2 {
    width: 25%;
    float: right;
    padding-left: 12px;
    padding-bottom: 12px;
    position: relative;
}
.hawal2 img {
    height: 160px;
}
.dwainhawal {
    display: inline-block;
    margin-left: -12px;
}
.dwainhawal .hel:after {
    margin-left: -100%;
    content: '';
    display: inline-block;
    height: 0.7em;
    vertical-align: bottom;
    width: 95%;
    border-top: 3px solid #136aa1;
}
.xabarr a {
    color: #136aa1;
}
.share {
    text-align: center;
    margin-top: 0;
    border-bottom: 1px #eee solid;
    padding-top: 5px;
    background: #fff;
}
.overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 999;
}
.overlay-content {
    position: relative;
    top: 46%;
    width: 80%;
    text-align: center;
    margin-top: 30px;
    margin: auto;
}
.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: #136aa1;
}
.overlay .closebtn:hover {
    color: #009eff;
}
.overlay input[type='text'] {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 80%;
    background: #fff;
    box-sizing: border-box;
}
.overlay input[type='text']:hover {
    background: #f1f1f1;
}
.overlay button {
    float: left;
    width: 20%;
    padding: 15px;
    background: #136aa1;
    font-size: 17px;
    border: none;
    cursor: pointer;
    color: #fff;
}
.overlay button:hover {
    background: #518acb;
}
.row > .column {
    padding: 0 8px;
}
.row:after {
    content: '';
    display: table;
    clear: both;
}
.column {
    float: right;
}
.modal {
    display: none;
    position: fixed;
    z-index: 4;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
}
.modal-content {
    position: relative;
    margin: auto;
    padding: 0;
    width: 70%;
    max-width: 1200px;
    text-align: center;
}
.close {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: 700;
}
.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}
.mySl {
    display: none;
}
.cursor {
    cursor: pointer;
}
.prev {
    right: 0;
}
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 53%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}
.next {
    left: 0;
    border-radius: 3px 0 0 3px;
}
.prev,
.next {
    background-color: rgba(0, 0, 0, 0.8);
}
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}
.mySl img {
    margin-bottom: -4px;
    width: auto;
    height: 480px;
    max-width: 860px;
}
.caption-container {
    text-align: center;
    background-color: #000;
    padding: 2px 16px;
    color: #fff;
}
.column img {
    margin-bottom: 15px;
}
.albumiwena {
    color: #111;
    border-bottom: 1px #333 solid;
    margin: 10px 10px 15px;
}
.rizkrdn li {
    display: flex;
    margin: 0 0 15px;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
}
.brga1 {
    float: right;
    flex: 35%;
    margin-left: 10px;
}
.brga2 {
    flex: 65%;
    margin: 5px 5px 10px;
}
.rizkrdn a {
    font-size: 17px;
    color: #111;
    font-weight: 700;
    line-height: 2;
}
.rizkrdn img {
    height: 180px;
    border-radius: 5px;
}
.rizkrdn h4 {
    font-size: 13px;
    color: #444;
    margin-top: 6px;
    font-weight: 300;
    line-height: 2;
}
.zyatr {
    display: none;
}
.pesh {
    float: left;
    background: #136aa1;
    margin: 10px;
    padding: 5px 20px;
    border-radius: 5px;
}
.pesh a {
    color: #fff;
}
.pash {
    background: #136aa1;
    margin: 10px;
    padding: 5px 20px;
    float: right;
    border-radius: 5px;
}
.pash a {
    color: #fff;
}
.mobile {
    display: none;
}
.mobi {
    display: none;
}
.in2 {
    margin-right: 40px;
}
.computer {
    display: block;
    padding: 0 0 30px;
    background: #f0f1f4;
}
#saraki {
    transition: margin 0.5s;
}
.sidenav {
    height: 100%;
    width: 235px;
    position: fixed;
    z-index: 5;
    top: 0;
    overflow-x: hidden;
    transition: margin 0.5s;
    opacity: 0;
    margin-right: -235px;
    background: #154566;
    margin-top: 62px;
}
.overlay3 {
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    transition: opacity 1s;
}
.nawarok {
    display: none !important;
}
.nawder {
    display: none !important;
}
.haftanama {
    padding-top: 12px;
}
.lifes {
    background: #fff;
    padding: 5px;
    position: absolute;
    color: #d64417;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.cul {
    background: #fff;
    padding: 5px;
    position: absolute;
    color: #ffab00;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.media {
    background: #fff;
    padding: 5px;
    position: absolute;
    color: #546e7a;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: noto kufi arabic, Rubik, sans-serif;
}
.hunhun {
    display: block;
    position: relative;
    overflow: hidden;
}
.info {
    float: right;
    padding: 10px 5px 5px 20px;
}
.info img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
}
.x1 {
    padding: 25px 5px 34px 0;
    margin: 0;
}
.x1 .xawan a {
    color: #555;
    font-size: 15px;
    background: #f1f3f5;
    padding: 0 9px;
    border-radius: 5px;
}
@media screen and (max-width: 1925px) {
    #saraki {
        zoom: 111%;
    }
}
@media screen and (max-width: 1450px) {
    #saraki {
        zoom: 108%;
    }
}
@media screen and (max-width: 1370px) {
    #saraki {
        zoom: 103%;
    }
}
@media screen and (max-width: 1285px) {
    #saraki {
        zoom: 94%;
    }
}
@media screen and (max-width: 1150px) {
    #saraki {
        zoom: 80%;
    }
}
@media screen and (max-width: 980px) {
    #saraki {
        zoom: 65%;
    }
}
@media screen and (max-width: 480px) {
    #saraki {
        zoom: 100%;
    }
    .chewa {
        background: #f0f1f4;
    }
    .chwar {
        background: #f0f1f4;
    }
    #navbar {
        display: none;
    }
    .xat {
        display: none;
    }
    .haftanama {
        display: none;
    }
    .slideshow {
        border-radius: 0;
    }
    .slide {
        height: auto;
    }
    .b1 {
        width: 100%;
        float: unset;
        height: 220px;
    }
    .b2 {
        width: 100%;
        float: unset;
        height: 175px;
    }
    .showw {
        font-size: 20px;
        padding: 12px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .sarma {
        display: none;
    }
    .slideshow img {
        height: 220px;
    }
    .tera {
        margin-left: 0;
    }
    .bambo {
        padding-top: 0;
    }
    .zhmarakan {
        width: 100%;
        padding-right: 6px;
        padding-left: 6px;
        height: unset;
        padding-top: 12px;
    }
    .zhmarakan h4 {
        padding: 0 10px 0 6px;
        height: 90px;
        border: unset;
    }
    .newan {
        border: unset;
        border-radius: unset;
    }
    .demo {
        border: 1px #bbb solid;
        height: 95px;
        background: #fff;
        margin-bottom: 13px;
        border-radius: 10px;
        overflow: hidden;
    }
    .block .sarder4 h4 {
        width: 73%;
        float: right;
    }
    .demo img {
        width: 90px;
        height: 95px;
        float: right;
        margin-top: 0;
        margin-right: 0;
        border-radius: 0;
    }
    .rastt {
        display: block;
        float: right;
        position: absolute;
        right: 0;
        padding: 85px 15px 85px 80px;
        z-index: 3;
        color: #eee;
        font-size: 25px;
    }
    .chapp {
        display: block;
        float: left;
        position: absolute;
        left: 0;
        padding: 85px 80px 85px 15px;
        z-index: 3;
        color: #eee;
        font-size: 25px;
    }
    .qabara1 {
        width: 100%;
        border-radius: 0;
    }
    .slideshow {
        margin-top: 30px;
    }
    .zhmarakanm {
        bottom: 0;
        left: 95px;
    }
    .info img {
        width: 90px;
        height: 90px;
        border-radius: 20px;
    }
    .x1 {
        padding: 25px 5px 0 0;
    }
    .x1 .xawan a {
    }
    .x1 h2 {
        padding-top: 15px;
    }
    .katwtar {
        display: block;
        padding: 5px;
    }
    .sarder {
        display: none !important;
    }
    .nawder {
        display: block !important;
        color: #111;
        margin: 0 0 12px;
        font-size: 15px;
        line-height: 1.8;
    }
    .nawarok {
        display: block !important;
    }
    .mobile {
        display: flex;
        background: #154566;
        position: fixed;
        top: 0;
        z-index: 2;
        width: 100%;
    }
    .mobi {
        display: none;
        padding-top: 15px;
    }
    .orgg {
        padding-top: 15px;
    }
    .in2 {
        margin-right: 0;
    }
    .computer {
        display: block;
        padding: 2px 0 0;
    }
    .one {
        text-align: right;
        flex: 28%;
        float: right;
        margin: 0 6px 0 0;
    }
    .two {
        text-align: center;
        flex: 40%;
        float: right;
        margin: 10px 3px 0 0;
    }
    .three {
        text-align: left;
        flex: 32%;
        float: right;
    }
    .sidenav a {
        padding: 8px;
        text-decoration: none;
        font-size: 17px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        color: #fff;
        display: block;
        transition: 0.3s;
        ine-height: 2;
    }
    .zman {
        display: inline-block;
    }
    .mySl img {
        width: 100% !important;
        height: auto;
    }
    .zman a {
        float: right;
        font-size: 16px;
        padding: 8px 18px 8px 12px;
    }
    .overlay3 {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .peshgr {
        margin-right: 12px;
        display: table;
    }
    .peshgr input {
        background: 0 0;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        border: 1px solid #fff;
        border-radius: 0;
        color: #fff;
        width: 92%;
        margin: 20px 5px;
    }
    iframe {
        max-width: 100% !important;
    }
    .peshgr a:before {
        margin-right: 0;
        margin-left: 8px;
        content: '\f104';
        float: right;
        font-family: FontAwesome;
        margin-top: 1px;
    }
    .fa-caret-down {
        float: left;
        margin-top: 8px;
        margin-left: 5px;
    }
    .dwam {
        width: 100%;
        bottom: 0;
        border-bottom: 1px #ccc solid;
    }
    .show {
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-image: linear-gradient(transparent, #111111),
            linear-gradient(transparent, #111111);
        margin: 6px 0 0;
        text-align: center;
    }
    .qabara1 {
        height: unset;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .qabara1:before {
        content: unset;
    }
    .drezha {
        display: none;
    }
    .title {
        width: 100%;
    }
    .chewa {
        width: 100%;
    }
    .saraki {
        height: auto;
        margin-top: 3.8em;
    }
    .seyam {
        padding-top: 0;
    }
    .daq {
        display: none;
    }
    .wenakanm {
        height: 330px;
    }
    .grng1,
    .grng2,
    .grng3 {
        width: 100%;
        padding: 15px 6px 0;
        height: 105px;
    }
    .tak {
        width: 100%;
        float: unset;
        max-width: none;
    }
    .tak img {
        height: 240px;
        border-radius: 5px;
    }
    .ko1 {
        width: 100%;
        float: unset;
        max-width: none;
        display: inline-block;
    }
    .hawal1 {
        width: 100%;
        padding: 20px 15px 15px;
        background: #fff;
        margin: 0 0 30px;
        border-bottom: 2px #ddd solid;
    }
    .der1 h4 {
        font-size: 13px;
        color: #333;
        margin-top: 15px;
        background: #fff;
        border-bottom: none;
        font-weight: 400;
        line-height: 1.8;
    }
    .yak {
        padding: 0;
    }
    .hawal {
        padding: 0 6px;
    }
    .chaw {
        padding: 0 6px;
    }
    .aburi .hel {
        padding: 6px 6px 12px;
    }
    .aburi .report1 {
        padding: 0 6px;
    }
    .raport .hel {
        padding: 6px 6px 12px;
    }
    .raport .report1 {
        padding: 0 6px;
    }
    .der2 h4 {
        background: #fff;
        margin: 0 0 12px;
        height: 90px;
    }
    .nuqta {
        padding: 0 6px;
    }
    .der3 h4 {
        background: #fff;
    }
    .iraqi img {
        height: unset;
    }
    .hawal .hel {
        padding: 6px 0 12px;
    }
    .hel {
        overflow: hidden;
    }
    .niw {
        width: 100%;
    }
    .zor {
        display: none;
    }
    .kam {
        width: 100%;
    }
    .sharawa {
        display: none;
    }
    .report1 {
        width: 100%;
    }
    .report2 {
        width: 100%;
    }
    .niwa {
        width: 100%;
        padding: 0 0 20px;
    }
    .bambo .niwa {
        width: 100%;
        padding: 0 0 15px;
    }
    .bambo .niwa2 .i1 {
        margin-right: 1px;
    }
    .wtar {
        padding: 0 6px;
    }
    .niwa1 {
        width: unset;
    }
    .niwa2 {
        width: unset;
        float: unset;
    }
    .niwa2 h3 {
        background: #fff;
        font-size: 15px;
        margin: 0;
    }
    .niwa2 .i2 {
        margin-top: -20px;
        margin-left: 0;
        background: unset;
    }
    .niwa1 img {
        border-radius: 5%;
        width: 90px;
        height: 110px;
        filter: grayscale(100%);
        transition: 1s;
        margin-left: 6px;
    }
    .xawanaka {
        margin-right: 111px;
        margin-top: 10px;
        background: unset;
    }
    .bambo .xawanaka {
        margin-top: 8px;
    }
    .bambo .niwa2 h3 {
        padding: 25px 20px 0;
    }
    .niwa2 h3 {
        padding: 25px 15px 0 6px;
    }
    .jegr .hel:after {
        width: 16.5em;
    }
    .life1 {
        display: none;
    }
    .life2 {
        width: 100%;
    }
    .life3 {
        width: 100%;
    }
    .hunhun {
        padding: 0 6px;
    }
    .hunar1 {
        width: 100%;
        padding-left: unset;
    }
    .multi1 {
        width: 100%;
    }
    .hunar {
        margin-left: unset;
        display: grid;
    }
    .hunar1 img {
        height: 240px;
    }
    .der8 h4 {
        font-size: 13px;
        font-weight: 400;
        color: #666;
        margin-top: 15px;
        background: #fff;
        height: unset;
        border: unset;
    }
    .seyam {
        overflow: hidden;
    }
    .muhim {
        padding-top: unset;
    }
    .openBtn {
        content: '\f015';
        border: none;
        width: 35px;
        height: 35px;
        color: #fff;
        margin-top: 8px;
        margin-bottom: 6px;
        background: #0b3450;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
        margin-left: 8px;
    }
    .saraki:before {
        content: none;
    }
    .lifes {
        background: #f0f1f4;
    }
    .life {
        padding: 0 6px;
    }
    .life2 img {
        height: 220px;
        border-radius: 5px;
    }
    .life2 {
        padding: 20px 15px 15px;
        background: #fff;
        margin: 0 0 30px;
    }
    .life3 img {
        height: 220px;
        border-radius: 5px;
    }
    .der7 {
        padding: 12px 0 0;
        position: relative;
    }
    .der7 h4 {
        font-size: 15px;
        font-weight: 400;
        color: #333;
        margin-top: 15px;
        background: #fff;
        height: unset;
    }
    .xaw {
        padding: 20px 15px 15px;
        background: #fff;
        margin: 0 0 30px;
        width: 100%;
    }
    .xawn {
        padding: 20px 15px 15px;
        background: #fff;
        margin: 0 0 30px;
        width: 100%;
    }
    .der6 h4 {
        font-size: 13px;
        font-weight: 400;
        color: #666;
        margin-top: 15px;
        background: #fff;
        height: unset;
        border: unset;
    }
    .cul {
        background: #f0f1f4;
    }
    .multimedia {
        padding: 0 6px;
        margin-top: -20px;
    }
    .multi img {
        height: 220px;
    }
    .media {
        background: #f0f1f4;
    }
    .zher {
        margin-top: 0;
        background: #fff !important;
        padding-bottom: 20px;
    }
    .sarata {
        flex: 100%;
        max-width: 100%;
    }
    .block {
        flex: 100%;
        max-width: 100%;
        padding: 0 6px;
    }
    .duri {
        background-color: #fff;
        border-radius: 5px;
        padding: 6px 15px 15px;
        margin: 70px 6px 0;
    }
    .penasa span {
        margin-left: 6px;
    }
    .intro h2 {
        color: #0e5d90;
        font-size: 19px;
    }
    .lewar img {
        height: auto;
    }
    .hawal2 {
        width: 100%;
        float: right;
        padding-left: 0;
        padding-bottom: 12px;
        position: relative;
    }
    .dwainhawal {
        margin: 20px 6px 0;
    }
    .kurtaka {
        font-size: 12px;
    }
    .brga1 {
        float: right;
        flex: 100%;
        margin-left: 10px;
    }
    .brga2 {
        flex: 100%;
        margin: 5px 5px 10px;
    }
    .rizkrdn li {
        display: block;
    }
    .bash {
        padding: 70px 6px 9px;
    }
    .bash .brga1 {
        margin-bottom: 15px;
        display: contents;
    }
    .bash .rizkrdn li {
        padding: 15px;
    }
    .bash .rizkrdn a {
        font-size: 15px;
    }
    .bash .rizkrdn h4 {
        font-size: 13px;
    }
    .penasa a {
        font-size: 10px;
    }
    .sarder4 h4 {
        font-size: 16px;
    }
    .nrx {
        font-size: 20px;
        line-height: 2;
    }
    .draw {
        line-height: 2;
    }
    .rsta h4 {
        font-size: 16px;
        padding: 5px 6px 0;
    }
}
